import AccountData from "../../../models/accountData";

const retrieveAccountDetails = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setAccountDetails: React.Dispatch<React.SetStateAction<AccountData | null>> // Adjust type for dictionary
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/client/account`,
            {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setAccountDetails(data.data); // Assuming the entire data object is the dictionary
            setLoading(false);
        } else {
            setLoading(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching account details', error);
    }
};

export default retrieveAccountDetails;
