/** @format */

import React, { useState, useRef, useEffect } from "react";
import { IoIosAdd } from "react-icons/io";
import Header from "../../../components/header";
import CustomersTable from "./customersTable";
import { IoMdAdd } from "react-icons/io";
import CustomerForm from "./customerForm";
import { SuccessNotification } from "../../../components/statusNotifications";

export default function Customers() {
    const [openAddCustomer, setOpenAddCustomer] = useState(false);
    const [createdCustomer, setCreatedCustomer] = useState(false);

    return (
        <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto">
            <div className="overflow-x-auto">
                <Header />
                <div className="flex flex-row justify-between items-center w-full mt-5">
                    <span className="text-2xl font-bold">Customers</span>
                    <button
                        onClick={() => setOpenAddCustomer(true)}
                        className="flex flex-row items-center rounded-lg bg-indigo-600 px-2 h-8 hover:bg-indigo-500"
                    >
                        <IoMdAdd size={16} style={{ color: "white" }} />
                        <p className="pl-1 text-white font-medium text-xs">
                            Create customer
                        </p>
                    </button>
                </div>
                <CustomersTable createdCustomer={createdCustomer} />
            </div>

            <CustomerForm
                openCustomerForm={openAddCustomer}
                setOpenCustomerForm={setOpenAddCustomer}
                setCustomerActionSuccess={setCreatedCustomer}
                mode="add"
            />

            <SuccessNotification
                title="Successfully created the customer"
                subtitle=""
                showNotification={createdCustomer}
                setShowNotification={setCreatedCustomer}
                // createdCustomer={createdCustomer}
                // setCreatedCustomer={setCreatedCustomer}
            />
        </div>
    );
}
