import LineChart from "./lineChart";
import MultiCalendar from "./multiCalendar";

export default function Charts() {
    return (
        <div>
            <MultiCalendar />
            <div className="mt-10 flex flex-row w-full space-x-10">
                <div className="w-full flex flex-col">
                    <span className="font-semibold mb-5">Total Bookings</span>
                    <LineChart />
                </div>
                <div className="w-full flex flex-col">
                    <span className="font-semibold mb-5">Total Revenue</span>
                    <LineChart />
                </div>
            </div>
            <div className="mt-10 flex flex-row w-full space-x-10">
                <div className="w-full flex flex-col">
                    <span className="font-semibold mb-5">
                        Total Participants Today
                    </span>
                    <LineChart />
                </div>
                <div className="w-full flex flex-col">
                    <span className="font-semibold mb-5">
                        Upcoming Bookings
                    </span>
                    <LineChart />
                </div>
            </div>
        </div>
    );
}
