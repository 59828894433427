/** @format */

import { useNavigate, useSearchParams } from "react-router-dom";
import Customer from "../../../models/customers";
import React, { useState, useEffect } from "react";
import { fetchCustomers } from "./api/customersApi";
import LoadingWheel from "../../../components/loadingWheel";
import Results from "../../../models/results";
import CustomerFilters from "./customerFilters";
import { SuccessNotification } from "../../../components/statusNotifications";

export interface Filters {
    email: string;
}

export default function CustomersTable({
    createdCustomer,
}: {
    createdCustomer: boolean;
}) {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
    const [allCustomersSelected, setAllCustomersSelected] = useState(false);

    const [filters, setFilters] = useState<Filters>({ email: "" });

    const [deletedRows, setDeletedRows] = useState(false);

    function calculateOffset(direction: string) {
        const offset = searchParams.get("offset");
        if (offset) {
            if (direction === "back") {
                return Number(offset) - 1;
            }
            return Number(offset) + 1;
        } else {
            return 1;
        }
    }

    function getOffset() {
        const offset = searchParams.get("offset");
        if (offset) {
            return Number(offset);
        } else {
            return 0;
        }
    }

    useEffect(() => {
        fetchCustomers(
            setLoading,
            setCustomers,
            setResults,
            searchParams.get("offset"),
            filters
        );
    }, [filters]);

    useEffect(() => {
        if (createdCustomer || deletedRows) {
            fetchCustomers(
                setLoading,
                setCustomers,
                setResults,
                searchParams.get("offset"),
                filters
            );
        }
    }, [createdCustomer, deletedRows]);

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedCustomers(
                customers.map((customer) => customer.customer_id)
            );
        } else {
            setAllCustomersSelected(false);
            setSelectedCustomers([]);
        }
    };

    // Handle individual checkbox click
    const handleCheckboxChange = (customerId: string) => {
        setSelectedCustomers((prevSelected) =>
            prevSelected.includes(customerId)
                ? prevSelected.filter((id) => id !== customerId)
                : [...prevSelected, customerId]
        );
    };

    return (
        <div>
            <CustomerFilters
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
                allCustomersSelected={allCustomersSelected}
                setAllCustomersSelected={setAllCustomersSelected}
                customers={customers}
                setCustomers={setCustomers}
                filters={filters}
                setFilters={setFilters}
                setDeletedRows={setDeletedRows}
            />
            {!loading && customers.length > 0 ? (
                <>
                    <div className="overflow-x-auto">
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    <th className="py-2">
                                        <input
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={handleSelectAll}
                                            checked={
                                                selectedCustomers.length ===
                                                    customers.length &&
                                                customers.length > 0
                                            }
                                        />
                                    </th>
                                    <th className="py-2">Full Name</th>
                                    <th className="py-2">Email</th>
                                    <th className="py-2">Customer ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map((customer) => (
                                    <tr
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/customers/${customer.customer_id}`
                                            )
                                        }
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <td className="py-3 flex items-center">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCheckboxChange(
                                                        customer.customer_id
                                                    );
                                                }}
                                                checked={selectedCustomers.includes(
                                                    customer.customer_id
                                                )}
                                            />
                                        </td>
                                        <td className="py-2.5">
                                            {customer.first_name +
                                                " " +
                                                customer.last_name}
                                        </td>
                                        <td className="">{customer.email}</td>
                                        <td className="">
                                            {customer.customer_id}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                    {results.showing_from}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                    {results.showing_to}
                                </span>{" "}
                                of{" "}
                                <span className="font-medium">
                                    {results.total_results}
                                </span>{" "}
                                results
                            </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <a
                                href={`/dashboard/customers?offset=${calculateOffset(
                                    "back"
                                )}`}
                                className={`${
                                    getOffset() === 0
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Previous
                            </a>
                            <a
                                href={`/dashboard/customers?offset=${calculateOffset(
                                    "forward"
                                )}`}
                                className={`${
                                    results.showing_to >= results.total_results
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Next
                            </a>
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? (
                        <LoadingWheel />
                    ) : (
                        <div className="flex flex-col items-center">
                            {filters.email !== "" ? (
                                <div className="flex flex-col items-center">
                                    <span className="text-sm text-gray-700 pb-3">
                                        Filters did not return any results.
                                    </span>
                                    <button
                                        onClick={() =>
                                            setFilters({ email: "" })
                                        }
                                        className="text-xs text-white bg-indigo-600 py-1.5 w-32 rounded-md hover:bg-indigo-500"
                                    >
                                        Remove Filters
                                    </button>
                                </div>
                            ) : (
                                <span className="text-sm text-gray-700 pb-3">
                                    No custumers created yet. Create one to view
                                    it..
                                </span>
                            )}
                        </div>
                    )}
                </div>
            )}

            <SuccessNotification
                title="Successfully deleted the row(s)"
                subtitle=""
                showNotification={deletedRows}
                setShowNotification={setDeletedRows}
            />
        </div>
    );
}
