import SingleCalendar from "./singleCalendar";
import { useEffect, useState } from "react";
import KPI from "../../../models/kpi";
import retrieveKPI from "./fetchData";

const stats = [
    {
        name: "Total Bookings",
        value: "$405,091.00",
        change: "+4.75%",
        changeType: "positive",
    },
    {
        name: "Total Revenue",
        value: "$12,787.00",
        change: "+54.02%",
        changeType: "negative",
    },
    {
        name: "Total Participants Today",
        value: "$245,988.00",
        change: "-1.39%",
        changeType: "positive",
    },
    {
        name: "Upcoming Bookings",
        value: "$30,156.00",
        change: "+10.18%",
        changeType: "negative",
    },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function KPICards() {
    const [loading, setLoading] = useState(true);
    const [kpiData, setKPIData] = useState<KPI[]>([]);

    useEffect(() => {
        retrieveKPI(setLoading, setKPIData); // Pass setAccountDetails
    }, []);

    return (
        <div>
            <SingleCalendar />
            <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-200 sm:grid-cols-2 lg:grid-cols-4">
                {kpiData.map((stat) => (
                    <div
                        key={stat.name}
                        className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-7 sm:px-6 xl:px-8"
                    >
                        <dt className="text-sm font-medium leading-6 text-gray-500">
                            {stat.name}
                        </dt>
                        <dd
                            className={classNames(
                                stat.changeType === "negative"
                                    ? "text-rose-600"
                                    : "text-gray-700",
                                "text-xs font-medium"
                            )}
                        >
                            {stat.change}
                        </dd>
                        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                            {stat.value}
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    );
}
