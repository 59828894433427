/** @format */

import { useState, useEffect, useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { IoMdAdd } from "react-icons/io";
import dayjs, { Dayjs } from "dayjs";
import WeekCalendar from "./weekCalendar";
import AddBooking from "./addBooking";
import { SuccessNotification } from "../../../components/statusNotifications";
import Events from "./events";
import BookingPopup from "./bookingPopup";

export default function BookingsCalendar() {
    const [openAddBooking, setOpenAddBooking] = useState(false);
    const [createdBooking, setCreatedBooking] = useState(false);

    const [selectedWeek, setSelectedWeek] = useState<Dayjs[]>([]); // use this variable for

    const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const containerNav = useRef<HTMLDivElement>(null);
    const containerOffset = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const startOfWeek = getStartOfWeekMonday(dayjs());
        updateSelectedWeek(startOfWeek);
    }, []);

    const getStartOfWeekMonday = (date: Dayjs) => {
        const weekday = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const diff = (weekday + 6) % 7; // Calculate difference to Monday
        return date.subtract(diff, "day").startOf("day");
    };

    const updateSelectedWeek = (startOfWeek: Dayjs) => {
        const weekDays: Dayjs[] = [];
        let day = startOfWeek;
        for (let i = 0; i < 7; i++) {
            weekDays.push(day);
            day = day.add(1, "day");
        }
        setSelectedWeek(weekDays);
        setCurrentDate(startOfWeek); // Update current date to reflect selected week
    };

    const handlePreviousWeek = () => {
        const startOfWeek = getStartOfWeekMonday(
            currentDate.subtract(1, "week")
        );
        updateSelectedWeek(startOfWeek);
    };

    const handleNextWeek = () => {
        const startOfWeek = getStartOfWeekMonday(currentDate.add(1, "week"));
        updateSelectedWeek(startOfWeek);
    };

    return (
        <div>
            <BookingPopup />
            <div className="flex h-full flex-col">
                <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        {selectedWeek[0]?.format("MMMM D")}
                        {selectedWeek[0]?.month() !== selectedWeek[6]?.month()
                            ? " - " + selectedWeek[6]?.format("MMMM D, YYYY")
                            : " - " + selectedWeek[6]?.format("D, YYYY")}
                    </h1>
                    <div className="flex items-center">
                        <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                            <button
                                type="button"
                                onClick={handlePreviousWeek}
                                className="flex h-8 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 hover:bg-gray-50"
                            >
                                <span className="sr-only">Previous week</span>
                                <ChevronLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <WeekCalendar
                                selectedWeek={selectedWeek}
                                setSelectedWeek={setSelectedWeek}
                                currentDate={currentDate}
                                setCurrentDate={setCurrentDate}
                                isCalendarVisible={isCalendarVisible}
                                setIsCalendarVisible={setIsCalendarVisible}
                            />
                            <button
                                type="button"
                                onClick={handleNextWeek}
                                className="flex h-8 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 hover:bg-gray-50"
                            >
                                <span className="sr-only">Next week</span>
                                <ChevronRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                        <div className="hidden md:ml-4 md:flex md:items-center">
                            <div className="mx-6 h-6 w-px bg-gray-300" />
                            <button
                                className="flex h-8 flex-row items-center rounded-lg bg-[#625bf6] px-2 hover:bg-indigo-500"
                                onClick={() => setOpenAddBooking(true)}
                            >
                                <IoMdAdd size={16} style={{ color: "white" }} />
                                <p className="pl-1 text-white font-medium text-xs">
                                    Create booking
                                </p>
                            </button>
                        </div>
                    </div>
                </header>
                <div
                    ref={container}
                    className="isolate flex flex-auto flex-col overflow-auto bg-white"
                >
                    <div
                        style={{ width: "165%" }}
                        className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
                    >
                        <div
                            ref={containerNav}
                            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
                        >
                            <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
                                {selectedWeek.map((day) => {
                                    const isToday = day.isSame(dayjs(), "day");
                                    return (
                                        <button
                                            key={day.format("YYYY-MM-DD")}
                                            type="button"
                                            className="flex flex-col items-center pb-3 pt-2"
                                        >
                                            {day.format("dd").charAt(0)}
                                            <span
                                                className={`mt-1 flex h-8 w-8 items-center justify-center font-semibold ${
                                                    isToday
                                                        ? "rounded-full bg-indigo-600 text-white"
                                                        : "text-gray-900"
                                                }`}
                                            >
                                                {day.format("D")}
                                            </span>
                                        </button>
                                    );
                                })}
                            </div>
                            <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
                                <div className="col-end-1 w-14" />
                                {selectedWeek.map((day) => {
                                    const isToday = day.isSame(dayjs(), "day");
                                    return (
                                        <div
                                            key={day.format("YYYY-MM-DD")}
                                            className="flex items-center justify-center py-3"
                                        >
                                            <span className="flex items-baseline">
                                                {day.format("ddd")}{" "}
                                                <span
                                                    className={`h-8 flex items-center justify-center font-semibold ${
                                                        day.isSame(
                                                            dayjs(),
                                                            "day"
                                                        )
                                                            ? "w-8 ml-1.5 rounded-full bg-indigo-600 text-white"
                                                            : "text-gray-900 ml-1"
                                                    }`}
                                                >
                                                    {day.format("D")}
                                                </span>
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex flex-auto">
                            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                            <div className="grid flex-auto grid-cols-1 grid-rows-1">
                                {/* Horizontal lines */}
                                <div
                                    className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                                    style={{
                                        gridTemplateRows:
                                            "repeat(48, minmax(3.5rem, 1fr))",
                                    }}
                                >
                                    <div
                                        ref={containerOffset}
                                        className="row-end-1 h-7"
                                    ></div>
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            12AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            1AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            2AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            3AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            4AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            5AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            6AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            7AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            8AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            9AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            10AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            11AM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            12PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            1PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            2PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            3PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            4PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            5PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            6PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            7PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            8PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            9PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            10PM
                                        </div>
                                    </div>
                                    <div />
                                    <div>
                                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                            11PM
                                        </div>
                                    </div>
                                    <div />
                                </div>

                                {/* Vertical lines */}
                                <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                                    <div className="col-start-1 row-span-full" />
                                    <div className="col-start-2 row-span-full" />
                                    <div className="col-start-3 row-span-full" />
                                    <div className="col-start-4 row-span-full" />
                                    <div className="col-start-5 row-span-full" />
                                    <div className="col-start-6 row-span-full" />
                                    <div className="col-start-7 row-span-full" />
                                    <div className="col-start-8 row-span-full w-8" />
                                </div>

                                {/* Events */}
                                <Events />
                            </div>
                        </div>
                    </div>
                </div>
                <AddBooking
                    openAddBooking={openAddBooking}
                    setOpenAddBooking={setOpenAddBooking}
                    setCreatedBooking={setCreatedBooking}
                />
                {/* <SuccessNotification
                    title="Successfully created the booking"
                    subtitle=""
                    createdCustomer={createdBooking}
                    setCreatedCustomer={setCreatedBooking}
                /> */}
            </div>
        </div>
    );
}
