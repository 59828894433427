import Customer from "../../../../models/customers";
import Results from "../../../../models/results";
import { Filters } from "../customersTable";
import { debounce } from 'lodash';
import { saveAs } from 'file-saver';

export const fetchCustomers = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>,
    setCount: React.Dispatch<React.SetStateAction<Results>>,
    offset: string | null,
    filters: Filters
) => {

    try {
        setLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers?${offset ? 'offset=' + encodeURIComponent(offset) : ''}${filters.email !== "" ? '&emailFilter=' + encodeURIComponent(filters.email) : ''}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                },
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setCustomers(data.data.customers);
            setCount(data.data.results)
            setLoading(false);
        } else {
            setLoading(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching account details', error);
    }
};

export const fetchCustomerData = async (
    customer_id: string | undefined,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomers: React.Dispatch<React.SetStateAction<Customer | null>>
) => {
    try {
        setLoading(true)
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers/${customer_id}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                }
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setCustomers(data.data); // Assuming the entire data object is the dictionary
            setLoading(false);
        } else {
            setLoading(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching account details', error);
    }
}

export const updateCustomerData = async (
    customer_id: string | undefined,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    dobDay: string,
    dobMonth: string,
    dobYear: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenCustomerForm: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomerActionSuccess: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    try {
        const jsonBody = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            dob_day: dobDay,
            dob_month: dobMonth,
            dob_year: dobYear
        }

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers/${customer_id}`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setOpenCustomerForm(false)
            setCustomerActionSuccess(true)
            setLoading(false);
            
            setTimeout(() => {
                setCustomerActionSuccess(false);
            }, 2000);
            
        } else {
            setLoading(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching account details', error);
    }
}

export const deleteCustomers = async (
    selectedCustomers: string[],
    setDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
    customers: Customer[],
    setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>,
    deselectCustomers: () => void,
    setDeletedRows: React.Dispatch<React.SetStateAction<boolean>>,
    mode: string,
    allCustomersSelected: boolean
) => {
    try {
        setDeleteLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers`,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                },
                body: JSON.stringify({ selectedCustomers, allCustomersSelected }),
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            if (mode === "table") {
                setDeleteLoading(false);
                setOpenDeleteModal(false);
                deselectCustomers()
                setDeletedRows(true)
                setTimeout(() => {
                    setDeletedRows(false);
                }, 2000);
            } else if (mode === "single") {
                setDeleteLoading(false);
                setOpenDeleteModal(false);
                setDeletedRows(true)
                setTimeout(() => {
                    setDeletedRows(false);
                }, 2000);
                
            }
            
        } else {
            setDeleteLoading(false);
            setOpenDeleteModal(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setDeleteLoading(false);
        setOpenDeleteModal(false);
        console.error('Error fetching account details', error);
    }
}

export const createCustomer = async (
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    dobDay: string,
    dobMonth: string,
    dobYear: string,
    setCustomerActionSuccess: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenAddCustomer: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
) => {
    try {
        setLoading(true)
        const jsonBody = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            dob_day: dobDay,
            dob_month: dobMonth,
            dob_year: dobYear
        }

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers`,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                },
                body: JSON.stringify(jsonBody),
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false)
            setOpenAddCustomer(false)
            setCustomerActionSuccess(true)
            setTimeout(() => {
                setCustomerActionSuccess(false);
            }, 2000);
        } else {
            setLoading(false)
            setErrorMessage(data.message)
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false)
        setErrorMessage("Server error, please try again or wait.")
        console.error('Error fetching account details', error);
    }
}

export const searchCustomers = debounce(async (searchText: string, setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (searchText.trim() === "") {
        setCustomers([]);
        return;
    }
    setLoading(true);
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers/search?search_text=${searchText}`, 
            {
                method: "GET",
                headers: {
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                }
            }
        );
        const data = await response.json();
        if (data.status === "success") {
            setCustomers(data.data.customers);
            setLoading(false);
        } else {
            console.error('Search failed:', data.message);
            setLoading(false);
        }
    } catch (error) {
        console.error('Search error:', error);
        setLoading(false);
    }
}, 300);

export const exportCustomers = async (
    selectedCustomers: string[],
    allCustomersSelected: boolean,
    setExportLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    try {
        setExportLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers/export`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                }, body: JSON.stringify({ selectedCustomers, allCustomersSelected }),
            },
        );

        if (!response.ok) {
            throw new Error("network error")
        }

        const blob = await response.blob();

        saveAs(blob, 'customers.csv')

        setExportLoading(false);
    } catch (error) {
        setExportLoading(false);
        console.error('Error fetching customer details', error);
    }
};
