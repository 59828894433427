/** @format */

import { useEffect } from "react";
import Header from "../../../components/header";
import SideBar from "../../../components/sideBar";
import BookingsCalendar from "./bookingsCalendar";

function BookingsPage() {
    useEffect(() => {
        const fetchBookings = async () => {
            // const response
        };
        fetchBookings();
    }, []);

    return (
        <div className="flex flex-row">
            <SideBar />
            <div className="flex flex-col w-full">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <BookingsCalendar />
            </div>
        </div>
    );
}

export default BookingsPage;
