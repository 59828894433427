/** @format */

import {
    CalendarDateRangeIcon,
    IdentificationIcon,
    MegaphoneIcon,
    CreditCardIcon,
} from "@heroicons/react/24/outline";

const features = [
    {
        name: "Calendar",
        description:
            "Stay organized with a fully personalized calendar that tracks all your go-kart bookings.",
        icon: CalendarDateRangeIcon,
    },
    {
        name: "Customer Information",
        description:
            "Access a comprehensive page with detailed information on your customers and their karting history.",
        icon: IdentificationIcon,
    },
    {
        name: "Reports",
        description:
            "Generate detailed reports to analyze and review your karting operations.",
        icon: MegaphoneIcon,
    },
    {
        name: "Manage Client Memberships",
        description:
            "Easily manage and update client memberships with a streamlined interface for all your subscription needs.",
        icon: CreditCardIcon,
    },
];

export default function Example() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">
                        Features
                    </h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                        Navigate Your Dashboard with Confidence
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Access all essential tools and features to manage your
                        karting business seamlessly.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <feature.icon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-white"
                                        />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">
                                    {feature.description}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
}
