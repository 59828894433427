import React, { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { MdOutlineCalendarMonth } from "react-icons/md";

const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

interface WeekCalendarProps {
    selectedWeek: Dayjs[];
    setSelectedWeek: (week: Dayjs[]) => void;
    currentDate: Dayjs;
    setCurrentDate: (date: Dayjs) => void;
    isCalendarVisible: boolean;
    setIsCalendarVisible: (visible: boolean) => void;
}

const WeekCalendar: React.FC<WeekCalendarProps> = ({
    selectedWeek,
    setSelectedWeek,
    currentDate,
    setCurrentDate,
    isCalendarVisible,
    setIsCalendarVisible,
}) => {
    const calendarRef = useRef<HTMLDivElement>(null);
    const toggleButtonRef = useRef<HTMLSpanElement>(null);

    const getStartOfWeekMonday = (date: Dayjs) => {
        const weekday = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const diff = (weekday + 6) % 7; // Calculate difference to Monday
        return date.subtract(diff, "day").startOf("day");
    };

    const handleWeekClick = (weekStart: Dayjs) => {
        // Calculate the full week based on the start of the week
        const weekDays: Dayjs[] = [];
        let day = weekStart;

        for (let i = 0; i < 7; i++) {
            weekDays.push(day);
            day = day.add(1, "day");
        }

        setSelectedWeek(weekDays);
    };

    const generateCalendarDays = () => {
        const startOfMonth = currentDate.startOf("month");
        const endOfMonth = currentDate.endOf("month");

        const startDate = getStartOfWeekMonday(startOfMonth);
        const endDate = getStartOfWeekMonday(endOfMonth).add(6, "days");

        const days: Dayjs[] = [];
        let day = startDate;

        while (day.isBefore(endDate, "day") || day.isSame(endDate, "day")) {
            days.push(day);
            day = day.add(1, "day");
        }

        return days;
    };

    const days = generateCalendarDays();
    const today = dayjs();

    // Handle click outside to close the calendar
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                calendarRef.current &&
                toggleButtonRef.current &&
                !calendarRef.current.contains(event.target as Node) &&
                !toggleButtonRef.current.contains(event.target as Node)
            ) {
                setIsCalendarVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsCalendarVisible]);

    const handleCalendarToggle = () => {
        if (!isCalendarVisible) {
            // When opening the calendar
            if (selectedWeek.length > 0) {
                const firstDayOfSelectedWeek = selectedWeek[0];
                const monthOfSelectedWeek = firstDayOfSelectedWeek.month();
                const currentMonth = currentDate.month();
                if (monthOfSelectedWeek !== currentMonth) {
                    setCurrentDate(firstDayOfSelectedWeek.startOf("month"));
                }
            }
        }
        setIsCalendarVisible(!isCalendarVisible);
    };

    return (
        <div className="relative">
            <span
                ref={toggleButtonRef}
                className="flex h-8 w-12 items-center justify-center border-y border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 hover:bg-gray-50 cursor-pointer"
                onClick={handleCalendarToggle}
            >
                <MdOutlineCalendarMonth className="h-6 w-6" />
            </span>
            <div
                ref={calendarRef}
                className={`absolute right-0 top-full mt-2 mx-auto bg-white rounded-xl shadow-lg transition-all duration-100 ease-in-out w-96 z-30 ${
                    isCalendarVisible
                        ? "scale-100 opacity-100"
                        : "scale-95 opacity-0 pointer-events-none"
                }`}
                style={{ transformOrigin: "top right" }}
            >
                <div className="p-4">
                    <div className="flex items-center justify-between mb-8">
                        <button
                            onClick={() =>
                                setCurrentDate(currentDate.subtract(1, "month"))
                            }
                            className="text-gray-600 hover:text-gray-800"
                        >
                            <GoChevronLeft className="h-5 w-5" />
                        </button>
                        <span className="text-sm font-semibold">
                            {currentDate.format("MMMM YYYY")}
                        </span>
                        <button
                            onClick={() =>
                                setCurrentDate(currentDate.add(1, "month"))
                            }
                            className="text-gray-600 hover:text-gray-800"
                        >
                            <GoChevronRight className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="grid grid-cols-7 text-center mb-3">
                        {daysOfWeek.map((day) => (
                            <div key={day} className="text-xs text-gray-500">
                                {day}
                            </div>
                        ))}
                    </div>
                    <div className="border border-gray-200 rounded-xl overflow-hidden">
                        <div className="grid grid-cols-7 bg-gray-200 gap-px">
                            {days.map((day, index) => {
                                const isToday = day.isSame(today, "day");
                                const isInSelectedWeek =
                                    selectedWeek &&
                                    selectedWeek.some((d) =>
                                        d.isSame(day, "day")
                                    );
                                const isCurrentMonth =
                                    day.month() === currentDate.month();

                                let dayBackgroundClass =
                                    "bg-white hover:bg-gray-100 hover:cursor-pointer";
                                let dayTextClass = "text-gray-800";

                                if (!isCurrentMonth) {
                                    dayBackgroundClass =
                                        "bg-gray-50 hover:bg-gray-100 hover:cursor-pointer";
                                    dayTextClass = "text-gray-400";
                                }

                                if (isInSelectedWeek) {
                                    if (
                                        index === 0 ||
                                        index === 6 ||
                                        index === 7 ||
                                        index === 13 ||
                                        index === 14 ||
                                        index === 20 ||
                                        index === 21 ||
                                        index === 27 ||
                                        index === 28 ||
                                        index === 34 ||
                                        index === 35 ||
                                        index === 41
                                    ) {
                                        dayBackgroundClass = "bg-indigo-600";
                                        dayTextClass = "text-white";
                                    } else {
                                        dayBackgroundClass = "bg-indigo-200";
                                        dayTextClass = "text-indigo-600";
                                    }
                                }

                                if (isToday) {
                                    dayTextClass = "text-indigo-600";
                                }

                                return (
                                    <div
                                        key={day.format("YYYY-MM-DD")}
                                        onClick={() => {
                                            const startOfWeek =
                                                getStartOfWeekMonday(day);
                                            const weekDays = [];
                                            let currentDay = startOfWeek;
                                            for (let i = 0; i < 7; i++) {
                                                weekDays.push(currentDay);
                                                currentDay = currentDay.add(
                                                    1,
                                                    "day"
                                                );
                                            }
                                            // Only set the selected week if it spans the current month
                                            if (
                                                weekDays.some(
                                                    (d) =>
                                                        d.month() ===
                                                        currentDate.month()
                                                )
                                            ) {
                                                handleWeekClick(startOfWeek);
                                            }
                                        }}
                                        className={`relative text-sm flex items-center justify-center h-11 ${dayBackgroundClass}`}
                                    >
                                        <span
                                            className={`relative ${dayTextClass}`}
                                        >
                                            {day.date()}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeekCalendar;
