/** @format */

const bookings = [
    {
        booking_id: "1",
        time: "8:00 AM - 9:00 AM",
        title: "Track Booked",
        column: "1",
        row: "1",
        session_time: 1,
    },
    {
        booking_id: "1",
        time: "8:00 AM - 9:00 AM",
        title: "Track Booked",
        column: "2",
        row: "1",
        session_time: 1,
    },
    {
        booking_id: "1",
        time: "8:00 AM - 9:00 AM",
        title: "Track Booked",
        column: "7",
        row: "1",
        session_time: 1,
    },
    {
        booking_id: "1",
        time: "8:00 AM - 9:00 AM",
        title: "Track Booked",
        column: "4",
        row: "1",
        session_time: 1,
    },
];

// style={{ gridRow: "2 / span 6" }}

export default function Events() {
    return (
        <ol
            className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
            style={{
                gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
            }}
        >
            {bookings.map((booking) => (
                <li
                    // className="relative mt-px flex sm:col-start-6"
                    className={`relative mt-px flex sm:col-start-${booking.column}`}
                    style={{ gridRow: "10 / span 10" }}
                >
                    <a
                        href="#"
                        className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100"
                    >
                        <p className="order-1 font-semibold text-blue-700">
                            {booking.title}
                        </p>
                        <p className="text-blue-500 group-hover:text-blue-700">
                            <time dateTime="2022-01-12T06:00">
                                {booking.time}
                            </time>
                        </p>
                    </a>
                </li>
            ))}
            <div className="sm:col-start-1 hidden"></div>
            <div className="sm:col-start-2 hidden"></div>
            <div className="sm:col-start-3 hidden"></div>
            <div className="sm:col-start-4 hidden"></div>
            <div className="sm:col-start-5 hidden"></div>
            <div className="sm:col-start-6 hidden"></div>
            <div className="sm:col-start-7 hidden"></div>
        </ol>
    );
}
