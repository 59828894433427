import SideBar from "../../../components/sideBar";
import Customers from "./customers";

export default function CustomersPage() {
    return (
        <div className="flex flex-row">
            <SideBar />
            <Customers />
        </div>
    );
}
