/** @format */
import React, { useState, useRef, useEffect } from "react";
import LoadingWheel from "../../../components/loadingWheel";

export default function LoginPage() {
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [failed, setFailed] = useState(false);

    const handleLogin = async () => {
        setLoading(true);
        const response = await fetch(
            process.env.REACT_APP_API_BASE_URL + "/api/v1/client/login",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }),
            }
        );
        const data = await response.json();
        if (data.status === "success") {
            setLoading(false);
        } else {
            setLoading(false);
            setFailed(true);
        }
    };

    return (
        <body className="bg-gray-50 h-screen">
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                alt="Your Company"
                                src="/logo.png"
                                className="mx-auto h-16 w-auto"
                            />
                            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Sign in to your account
                            </h2>
                        </div>
                        <div className="space-y-6 mt-10">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        disabled={loading}
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        autoComplete="email"
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        disabled={loading}
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        autoComplete="current-password"
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            {failed ? (
                                <span className="text-red-500 text-sm">
                                    Email or password is incorrect.
                                </span>
                            ) : (
                                <></>
                            )}

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input
                                        disabled={loading}
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                    <label
                                        htmlFor="remember-me"
                                        className="ml-3 block text-sm leading-6 text-gray-900"
                                    >
                                        Remember me
                                    </label>
                                </div>

                                <div className="text-sm leading-6">
                                    <a
                                        href="#"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                    >
                                        Forgot password?
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button
                                    disabled={email.length < 1 || loading}
                                    onClick={handleLogin}
                                    type="submit"
                                    className={`${
                                        email.length >= 1 && !loading
                                            ? "bg-indigo-600 hover:bg-indigo-500 cursor-pointer transition"
                                            : email.length >= 1 && loading
                                            ? "bg-indigo-500 cursor-default transition"
                                            : "bg-indigo-300 cursor-default transition"
                                    } items-center flex w-full justify-center rounded-md px-3 h-9 text-sm font-semibold leading-6 text-white shadow-sm `}
                                >
                                    {loading ? (
                                        <LoadingWheel />
                                    ) : (
                                        <span>Sign in</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?{" "}
                        <a
                            href="/contact"
                            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                        >
                            Contact us
                        </a>
                    </p>
                </div>
            </div>
        </body>
    );
}
