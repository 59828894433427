import Footer from "../landing/footer";
import Contact from "./contact";

export default function ContactPage() {
    return (
        <div>
            <Contact />
            <Footer />
        </div>
    );
}
