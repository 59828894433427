/** @format */

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

type BookingStatus = "Upcoming" | "Ongoing" | "Completed" | "Cancelled";

// Define your statuses object with exact keys matching the BookingStatus type
const statuses: Record<BookingStatus, string> = {
    Upcoming: "text-blue-700 bg-blue-50 border border-blue-500",
    Ongoing: "text-green-700 bg-green-50 border border-green-500",
    Completed: "text-gray-700 bg-gray-50 border border-gray-500",
    Cancelled: "text-red-700 bg-red-50 border border-red-500",
};

interface Booking {
    booking_id: string;
    race_type: string;
    date: string;
    time: string;
    duration: string;
    num_participants: string;
    status: BookingStatus;
}

const bookings: Booking[] = [
    {
        booking_id: "",
        race_type: "1 Carrera",
        date: "",
        time: "",
        duration: "",
        num_participants: "",
        status: "Upcoming",
    },
    {
        booking_id: "",
        race_type: "1 Carrera",
        date: "",
        time: "",
        duration: "",
        num_participants: "",
        status: "Ongoing",
    },
    {
        booking_id: "",
        race_type: "1 Carrera",
        date: "",
        time: "",
        duration: "",
        num_participants: "",
        status: "Completed",
    },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function CustomerBookings() {
    return (
        <div className="pt-20">
            <div className="flex flex-row justify-between">
                <span className="font-bold text-xl">Recent Bookings</span>
                <a
                    href="/bookings?customer_id=cus_1249423"
                    className="text-indigo-600 font-semibold"
                >
                    View All
                </a>
            </div>
            <div className="pb-6"></div>
            <ul
                role="list"
                className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
            >
                {bookings.map((booking) => (
                    <li
                        key={booking.booking_id}
                        className="overflow-hidden rounded-xl border border-gray-200 relative "
                    >
                        <div
                            className={classNames(
                                statuses[booking.status],
                                "rounded-md px-2 py-1.5 text-xs font-medium absolute right-2 top-2"
                            )}
                        >
                            {booking.status}
                        </div>
                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 pl-6 py-3">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                                {booking.race_type}
                            </div>
                        </div>
                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Date</dt>
                                <dd className="text-gray-700">
                                    <time dateTime={booking.date}>
                                        {booking.time}
                                    </time>
                                </dd>
                            </div>
                            <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Group Size</dt>
                                <dd className="flex items-start gap-x-2">
                                    <div className="font-medium text-gray-900">
                                        {booking.num_participants}
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </li>
                ))}
            </ul>
        </div>
    );
}
