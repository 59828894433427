/** @format */

import React, { useState, useEffect } from "react";
import retrieveAccountDetails from "./fetchData";
import Header from "../../../components/header";
import LoadingWheel from "../../../components/loadingWheel";
import AccountData from "../../../models/accountData";

export default function SettingsGeneral() {
    const [loading, setLoading] = useState(true);
    const [accountDetails, setAccountDetails] = useState<AccountData | null>(
        null
    );

    useEffect(() => {
        retrieveAccountDetails(setLoading, setAccountDetails); // Pass setAccountDetails
    }, []);

    if (loading) {
        return (
            <div className="flex flex-grow items-center h-screen justify-center pb-64">
                <LoadingWheel />
            </div>
        );
    }

    if (!accountDetails) {
        return <div>Account details not found</div>;
    }

    return (
        <div className="flex-grow sm:px-10 px-4">
            <Header />
            <div className="">
                <main className="pb-16">
                    <div className="mx-auto space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                        <div>
                            <h2 className="text-2xl font-bold">
                                Business Account
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                If this information changes, please make sure to
                                make the necessary changes here.
                            </p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        Full Name
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">
                                            {accountDetails?.first_name +
                                                " " +
                                                accountDetails?.last_name}
                                        </div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        Email address
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">
                                            {accountDetails?.email}
                                        </div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        Phone Number
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">
                                            {accountDetails?.phone_number}
                                        </div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        <div>
                            <h2 className="text-base font-semibold leading-7 ">
                                Business Address
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                If this information changes, please make sure to
                                make the necessary changes here.
                            </p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        Address
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">
                                            {accountDetails?.address}
                                        </div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        Postal Code
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">
                                            {accountDetails?.postal_code}
                                        </div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        Country
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">
                                            {accountDetails?.country}
                                        </div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        City
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">
                                            {accountDetails?.city}
                                        </div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="py-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        State
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">
                                            {accountDetails?.state}
                                        </div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                            </dl>

                            <div className="flex border-t border-gray-100 pt-6">
                                <button
                                    type="button"
                                    className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                                >
                                    <span aria-hidden="true">+</span> Add
                                    another bank
                                </button>
                            </div>
                        </div>

                        <div>
                            <h2 className="text-base font-semibold leading-7 ">
                                Integrations
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                Connect applications to your account.
                            </p>

                            <ul
                                role="list"
                                className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
                            >
                                <li className="flex justify-between gap-x-6 py-6">
                                    <div className="font-medium ">
                                        QuickBooks
                                    </div>
                                    <button
                                        type="button"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                    >
                                        Update
                                    </button>
                                </li>
                            </ul>

                            <div className="flex border-t border-gray-100 pt-6">
                                <button
                                    type="button"
                                    className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                                >
                                    <span aria-hidden="true">+</span> Add
                                    another application
                                </button>
                            </div>
                        </div>

                        <div>
                            <h2 className="text-base font-semibold leading-7 ">
                                Language and dates
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                Choose what language and date format to use
                                throughout your account.
                            </p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        Language
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">English</div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                        Date format
                                    </dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="">DD-MM-YYYY</div>
                                        <button
                                            type="button"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
