/** @format */

import FeaturesSection from "./featuresSection";
import Footer from "./footer";
import HeroSection from "./heroSection";
import PricingSection from "./pricingSection";

function LandingPage() {
    return (
        <div>
            <HeroSection />
            <FeaturesSection />
            <PricingSection />
            <Footer />
        </div>
    );
}

export default LandingPage;
