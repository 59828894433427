/** @format */

import { RxHamburgerMenu } from "react-icons/rx";
import { GrCircleQuestion } from "react-icons/gr";
import { FaCirclePlus } from "react-icons/fa6";
import { FaRegBell } from "react-icons/fa6";
import SettingsDropdown from "./settingsDropdown";
import HeaderSearch from "./searchBar";
import React, { useState, useEffect } from "react";
import MobileSideBar from "./mobileSidebar";

export default function Header() {
    const [mobileSidebar, setMobileSidebar] = useState(false);

    const toggleSidebar = () => {
        setMobileSidebar(!mobileSidebar);
    };

    return (
        <div>
            <MobileSideBar
                hidden={!mobileSidebar}
                toggleSidebar={toggleSidebar}
            />
            <div className="sm:block hidden">
                <div className="flex flex-row justify-between bg-white py-5 top-0">
                    <div className="flex flex-row items-center">
                        <div className="lg:hidden pr-3">
                            <RxHamburgerMenu
                                onClick={() => setMobileSidebar(true)}
                                className="w-5 h-5 cursor-pointer"
                            />
                        </div>
                        <HeaderSearch />
                    </div>

                    <div className="flex flex-row items-center space-x-5 relative">
                        <GrCircleQuestion className="w-5 h-5 hover:cursor-pointer hover:text-indigo-600" />
                        <FaRegBell className="w-5 h-5 hover:cursor-pointer hover:text-indigo-600" />
                        <SettingsDropdown />
                        <FaCirclePlus className="w-5 h-5 text-indigo-600 hover:cursor-pointer" />
                    </div>
                </div>
            </div>
            <div className="sm:hidden">
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between bg-white py-3 top-0 items-center">
                        <RxHamburgerMenu
                            onClick={() => setMobileSidebar(true)}
                            className="w-5 h-5 cursor-pointer"
                        />
                        <div className="flex flex-row h-8 items-center space-x-5 relative">
                            <GrCircleQuestion className="w-5 h-5 hover:cursor-pointer hover:text-indigo-600" />
                            <FaRegBell className="w-5 h-5 hover:cursor-pointer hover:text-indigo-600" />
                            <SettingsDropdown />
                            <FaCirclePlus className="w-5 h-5 text-indigo-600 hover:cursor-pointer" />
                        </div>
                    </div>
                    <div className="pb-2">
                        <HeaderSearch />
                    </div>
                </div>
            </div>
        </div>
    );
}
