/** @format */

import { Line } from "react-chartjs-2";
import { Component } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// Register the components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = () => {
    const data = {
        labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
        ],
        datasets: [
            {
                label: "Sales",
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: "#4F46E5", // indigo-600
                backgroundColor: "#4F46E5",
                pointRadius: 0, // Remove the visible dots
                borderWidth: 4, // Make the line thicker
                hitRadius: 100, // Increase the hit radius to make hovering easier
                hoverRadius: 6, // Optional: size of the hover effect
                pointHoverRadius: 0, // Optional: size of the hover effect when point is hovered
            },
        ],
    };
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false, // Remove the legend
            },
            title: {
                display: false,
                text: "Monthly Sales Data",
            },
        },
        scales: {
            x: {
                grid: {
                    display: true, // Display vertical grid lines
                },
            },
            y: {
                grid: {
                    display: false, // Display horizontal grid lines
                },
            },
        },
    };
    return (
        <div className="h-60" style={{ width: "99%" }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default LineChart;
