/** @format */

import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../components/loadingWheel";
import { XCircleIcon } from "@heroicons/react/20/solid";

function BookingPopup() {
    const [openBookingPopup, setOpenBookingPopup] = useState(true);

    return (
        <Dialog
            open={openBookingPopup}
            onClose={setOpenBookingPopup}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenBookingPopup(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>
                        <div className="absolute right-0 bottom-0 pr-4 pb-4">
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto items-center"
                                >
                                    Add customer
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="sm:flex sm:items-start w-full">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold leading-6 text-gray-900 pb-5"
                                >
                                    Booking Information
                                </DialogTitle>
                                <div className="w-full h-px bg-gray-200"></div>
                                <div className="flex flex-col space-y-5 py-5">
                                    <div className="flex flex-row">
                                        <span className="font-medium text-sm w-32">
                                            Session ID:
                                        </span>
                                        <span className="text-sm">
                                            19028472
                                        </span>
                                    </div>
                                    <div className="flex flex-row">
                                        <span className="font-medium text-sm w-32">
                                            Race:
                                        </span>
                                        <span className="text-sm">
                                            1 Carrera (15 minutes)
                                        </span>
                                    </div>
                                    <div className="flex flex-row">
                                        <span className="font-medium text-sm w-32">
                                            Date:
                                        </span>
                                        <span className="text-sm">
                                            10:00 AM, 17 January, 2024
                                        </span>
                                    </div>
                                </div>
                                <div className="w-full h-px bg-gray-200"></div>
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold leading-6 text-gray-900 pt-3 pb-2"
                                >
                                    Participants
                                </DialogTitle>

                                <div className="flex flex-row py-2">
                                    <span className="font-medium text-sm w-32">
                                        Name:
                                    </span>
                                    <span className="text-sm">
                                        Andre Maytorena
                                    </span>
                                </div>
                                <div className="flex flex-row pb-2">
                                    <span className="font-medium text-sm w-32">
                                        Group size:
                                    </span>
                                    <span className="text-sm">4</span>
                                </div>
                                <div className="w-full h-px bg-gray-200"></div>

                                <div className="flex flex-row py-2">
                                    <span className="font-medium text-sm w-32">
                                        Name:
                                    </span>
                                    <span className="text-sm">
                                        Andre Maytorena
                                    </span>
                                </div>
                                <div className="flex flex-row pb-2">
                                    <span className="font-medium text-sm w-32">
                                        Group size:
                                    </span>
                                    <span className="text-sm">4</span>
                                </div>
                                <div className="w-full h-px bg-gray-200"></div>

                                <div className="flex flex-row py-2">
                                    <span className="font-medium text-sm w-32">
                                        Name:
                                    </span>
                                    <span className="text-sm">
                                        Andre Maytorena
                                    </span>
                                </div>
                                <div className="flex flex-row pb-2">
                                    <span className="font-medium text-sm w-32">
                                        Group size:
                                    </span>
                                    <span className="text-sm">4</span>
                                </div>
                                <div className="w-full h-px bg-gray-200"></div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default BookingPopup;
