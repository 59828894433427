import React, { useRef, useEffect, useState } from "react";
import { Filters } from "./customersTable";

interface FilterByEmailProps {
    isOpen: boolean;
    onClose: () => void;
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    emailButtonRef: React.RefObject<HTMLButtonElement>; // Add this prop
}

export const FilterByEmail: React.FC<FilterByEmailProps> = ({
    isOpen,
    onClose,
    filters,
    setFilters,
    emailButtonRef, // Destructure this prop
}) => {
    const filterRef = useRef<HTMLDivElement>(null);

    const [emailFilter, setEmailFilter] = useState("");

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                filterRef.current &&
                !filterRef.current.contains(event.target as Node) &&
                emailButtonRef.current &&
                !emailButtonRef.current.contains(event.target as Node) // Exclude clicks on email button
            ) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose, emailButtonRef]);

    const handleApply = () => {
        setFilters({
            email: emailFilter,
        });
        onClose();
    };

    return (
        <div
            ref={filterRef}
            className={`absolute w-64 h-32 bg-white z-50 top-7 rounded-md border border-gray-200 shadow-sm px-2 flex flex-col justify-between py-2 transition-opacity duration-300 ease-in-out ${
                isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
        >
            <div className="font-medium text-sm">Filter by Email</div>
            <input
                id="email"
                name="email"
                type="email"
                className="block w-full rounded-md border-0 py-0.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs leading-6"
                value={emailFilter}
                onChange={(e) => setEmailFilter(e.target.value)}
            />
            <button
                onClick={handleApply}
                className="w-full bg-indigo-600 text-xs text-white h-7 rounded-md hover:bg-indigo-500"
            >
                Apply
            </button>
        </div>
    );
};
