import React, { useRef } from "react";
import { PiExport } from "react-icons/pi";
import { MdOutlineDelete } from "react-icons/md";
import { HiMiniXMark } from "react-icons/hi2";
import DeleteModal from "./deleteCustomerModal";
import Customer from "../../../models/customers";
import { IoAddOutline } from "react-icons/io5";
import { LiaFilterSolid } from "react-icons/lia";
import { FilterByEmail } from "./filterPopups";
import { IoClose } from "react-icons/io5";
import { Filters } from "./customersTable";
import { useState } from "react";
import { exportCustomers } from "./api/customersApi";
import LoadingWheel from "../../../components/loadingWheel";

interface CustomerFiltersProps {
    selectedCustomers: string[];
    setSelectedCustomers: React.Dispatch<React.SetStateAction<string[]>>;
    allCustomersSelected: boolean;
    setAllCustomersSelected: React.Dispatch<React.SetStateAction<boolean>>;
    customers: Customer[];
    setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    setDeletedRows: React.Dispatch<React.SetStateAction<boolean>>;
}

function CustomerFilters({
    selectedCustomers,
    setSelectedCustomers,
    allCustomersSelected,
    setAllCustomersSelected,
    customers,
    setCustomers,
    filters,
    setFilters,
    setDeletedRows,
}: CustomerFiltersProps) {
    const emailButtonRef = useRef<HTMLButtonElement>(null);

    const deselectCustomers = () => {
        setAllCustomersSelected(false);
        setSelectedCustomers([]);
    };

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const toggleFilter = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    return (
        <div className="py-5 flex flex-row items-center">
            {selectedCustomers.length > 0 ? (
                <div className="flex flex-row items-center justify-between w-full h-8">
                    <div className="flex flex-row items-center space-x-4">
                        <div className="flex flex-row items-center">
                            <button
                                onClick={deselectCustomers}
                                className="h-6 w-6 bg-gray-100 border border-gray-300 hover:border-gray-400 hover:bg-gray-200 rounded-md items-center justify-center flex"
                            >
                                <HiMiniXMark className="text-gray-600" />
                            </button>
                            <span className="text-sm pl-2">
                                {!allCustomersSelected
                                    ? selectedCustomers.length +
                                      " selected rows"
                                    : "All rows in table selected"}
                            </span>
                        </div>
                        {!allCustomersSelected ? (
                            <button
                                onClick={() => setAllCustomersSelected(true)}
                                className="text-indigo-600 text-sm px-2 h-7 hover:bg-indigo-100 transition-all rounded-md"
                            >
                                Select all rows in table
                            </button>
                        ) : (
                            <></>
                        )}
                        <div className="h-7 bg-gray-200 w-px"></div>
                        <button
                            onClick={() =>
                                exportCustomers(
                                    selectedCustomers,
                                    allCustomersSelected,
                                    setExportLoading
                                )
                            }
                            className="flex flex-row items-center text-xs px-2 h-7 bg-indigo-600 rounded-md text-white hover:bg-indigo-500"
                        >
                            {exportLoading ? (
                                <LoadingWheel color="white" />
                            ) : (
                                <PiExport className="w-4 h-4" />
                            )}
                            <span className="pl-1.5">Export to CSV</span>
                        </button>
                    </div>
                    <div>
                        <DeleteModal
                            openDeleteModal={openDeleteModal}
                            setOpenDeleteModal={setOpenDeleteModal}
                            selectedCustomers={selectedCustomers}
                            customers={customers}
                            setCustomers={setCustomers}
                            deselectCustomers={deselectCustomers}
                            setDeletedRows={setDeletedRows}
                            allCustomersSelected={allCustomersSelected}
                        />
                        <button
                            onClick={() => setOpenDeleteModal(true)}
                            className="flex flex-row items-center text-xs px-2 py-1.5 bg-red-600 rounded-md text-white"
                        >
                            <MdOutlineDelete className="w-4 h-4" />{" "}
                            <span className="pl-1.5">Delete rows</span>
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex flex-row items-center justify-between w-full h-8">
                    <div className="flex flex-row items-center space-x-2">
                        <div className="relative">
                            <button
                                ref={emailButtonRef}
                                onClick={toggleFilter}
                                className="text-xs flex flex-row items-center border border-dashed border-gray-400 rounded-xl px-2.5 py-0.5 hover:bg-gray-100 hover:border-gray-500"
                            >
                                {filters.email === "" ? (
                                    <IoAddOutline className="h-4 w-4 text-gray-700" />
                                ) : (
                                    <IoClose
                                        onClick={() =>
                                            setFilters({ email: "" })
                                        }
                                        className="h-4 w-4 text-red-700"
                                    />
                                )}
                                <span className="pl-1 font-normal text-gray-700">
                                    Email
                                    <span className="pl-2 text-indigo-600">
                                        {filters.email}
                                    </span>
                                </span>
                            </button>
                            <FilterByEmail
                                isOpen={isFilterOpen}
                                onClose={() => setIsFilterOpen(false)}
                                filters={filters}
                                setFilters={setFilters}
                                emailButtonRef={emailButtonRef} // Pass ref to FilterByEmail
                            />
                        </div>
                        {/* Other filter buttons */}
                    </div>
                    <div></div>
                </div>
            )}
        </div>
    );
}

export default CustomerFilters;
