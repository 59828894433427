import Customer from "../../../../models/customers";
import Results from "../../../../models/results";

export const fetchCustomers = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>,
    setCount: React.Dispatch<React.SetStateAction<Results>>,
    offset: string | null
) => {

    try {
        setLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers?${offset ? 'offset=' + encodeURIComponent(offset) : ''}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                },
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setCustomers(data.data.customers);
            setCount(data.data.results)
            setLoading(false);
        } else {
            setLoading(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching account details', error);
    }
};

export const fetchCustomerData = async (
    customer_id: string | undefined,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomers: React.Dispatch<React.SetStateAction<Customer | null>>
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers/${customer_id}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                }
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setCustomers(data.data); // Assuming the entire data object is the dictionary
            setLoading(false);
        } else {
            setLoading(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching account details', error);
    }
}

export const deleteCustomers = async (
    selectedCustomers: string[],
    setDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
    customers: Customer[],
    setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>,
    deselectCustomers: () => void
) => {
    try {
        setDeleteLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers`,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                },
                body: JSON.stringify({ selectedCustomers }),
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setDeleteLoading(false);
            setOpenDeleteModal(false);
            const updatedCustomers = customers.filter(
                (customer) => !selectedCustomers.includes(customer.customer_id)
            );
            
            setCustomers(updatedCustomers);
            deselectCustomers()
        } else {
            setDeleteLoading(false);
            setOpenDeleteModal(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setDeleteLoading(false);
        setOpenDeleteModal(false);
        console.error('Error fetching account details', error);
    }
}

export const createCustomer = async (
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    dobDay: string,
    dobMonth: string,
    dobYear: string,
    setCreatedCustomer: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenAddCustomer: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
) => {
    try {
        setLoading(true)
        const jsonBody = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            dob_day: dobDay,
            dob_month: dobMonth,
            dob_year: dobYear
        }

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/customers`,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJlbWF5dG9AZ21haWwuY29tIiwiY29tcGFueV9pZCI6IjM1ZWFhNTQxLTMwNDUtNGNlZC05ZDFjLWU0ZDVlNWEyYjk4MyIsImV4cCI6MTcyNjE3MzgyMCwicGVybWlzc2lvbl9sZXZlbCI6ImFkbWluIn0.01gDsoDUI7MV-Rvq3r-SMxNv11MlWYXdFG_7kE-8pSw"
                },
                body: JSON.stringify(jsonBody),
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false)
            setOpenAddCustomer(false)
            setCreatedCustomer(true)
            setTimeout(() => {
                setCreatedCustomer(false);
            }, 2000);
        } else {
            setLoading(false)
            setErrorMessage(data.message)
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false)
        setErrorMessage("Server error, please try again or wait.")
        console.error('Error fetching account details', error);
    }
}
