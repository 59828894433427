/** @format */

import { useParams } from "react-router-dom";
import SideBar from "../../../../components/sideBar";
import Header from "../../../../components/header";
import { IoIosAddCircle } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";
import AddParticipant from "./addParticipant";
import { useState } from "react";

const participants = [
    {
        primary_booker: {
            full_name: "Andre Maytorena",
            customer_id: "cus_2190481248",
        },
        participants: [
            {
                full_name: "Jorge Cuevas",
                customer_id: "cus_123132",
            },
            {
                full_name: "",
                customer_id: "",
            },
            {
                full_name: "",
                customer_id: "",
            },
        ],
    },
    {
        primary_booker: {
            full_name: "Ian Maytorena",
            customer_id: "cus_2190481248",
        },
        participants: [
            {
                full_name: "Dulce Cuevas",
                customer_id: "cus_123132",
            },
            {
                full_name: "",
                customer_id: "",
            },
        ],
    },
];

function getCapacityStatus(participants: number, maxCapacity: number) {
    const percentageFilled = (participants / maxCapacity) * 100;

    if (percentageFilled === 0) {
        return "text-blue-700 bg-blue-50 border border-blue-500"; // No Bookings
    } else if (percentageFilled < 25) {
        return "text-blue-700 bg-blue-50 border border-blue-500"; // Low Bookings
    } else if (percentageFilled < 75) {
        return "text-blue-700 bg-blue-50 border border-blue-500"; // Partially Filled
    } else if (percentageFilled < 100) {
        return "text-blue-700 bg-blue-50 border border-blue-500"; // Nearly Full
    } else {
        return "text-red-700 bg-red-50 border border-red-500"; // Fully Booked
    }
}

function BookingDetails() {
    const [openAddParticipant, setOpenAddParticipant] = useState(false);

    return (
        <div className="pt-4">
            <div className="pb-4 flex flex-col sm:flex-row justify-between sm:items-center">
                <span className="text-2xl font-bold">Booking Information</span>
                <div className="flex flex-row space-x-4 sm:pt-0 pt-4">
                    <div
                        className={`${getCapacityStatus(
                            3,
                            3
                        )} rounded-md px-2 py-1.5 text-sm font-medium`}
                    >
                        3/3 Participants
                    </div>
                    <div
                        className={`${getCapacityStatus(
                            3,
                            3
                        )} rounded-md px-2 py-1.5 text-sm font-medium`}
                    >
                        Max Capacity
                    </div>
                </div>
            </div>

            <div className="w-full h-px bg-gray-200"></div>
            <div className="flex flex-col space-y-5 py-5">
                <div className="flex flex-row sm:justify-normal justify-between">
                    <span className="font-medium text-sm w-44">
                        Session ID:
                    </span>
                    <span className="text-sm sm:text-left text-right">
                        19028472
                    </span>
                </div>
                <div className="flex flex-row sm:justify-normal justify-between">
                    <span className="font-medium text-sm w-44">Race:</span>
                    <span className="text-sm sm:text-left text-right">
                        1 Carrera (15 minutes)
                    </span>
                </div>
                <div className="flex flex-row sm:justify-normal justify-between">
                    <span className="font-medium text-sm w-44">Date:</span>
                    <span className="text-sm sm:text-left text-right">
                        10:00 AM, 17 January, 2024
                    </span>
                </div>
            </div>
            <div className="w-full h-px bg-gray-200"></div>

            <div className=" pt-4 sm:pb-4 pb-0">
                <span className="font-semibold text-gray-900">
                    Participants
                </span>
            </div>

            {participants.map((participant) => (
                <div className="flex flex-col sm:flex-row items-start pt-8">
                    <span className="font-medium text-sm w-44">
                        Primary Booker:
                    </span>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center pb-2">
                            <span className="text-base pr-3">
                                {participant.primary_booker.full_name}
                            </span>
                            <div className="border border-gray-200 px-2 py-0.5 text-xs font-medium rounded-md">
                                {participant.participants.length} participants
                            </div>
                        </div>
                        <div className="space-y-2">
                            {participant.participants.map((sub_participant) => (
                                <div
                                    key={sub_participant.full_name}
                                    className="pl-4"
                                >
                                    {sub_participant.customer_id === "" ? (
                                        <span
                                            onClick={() =>
                                                setOpenAddParticipant(true)
                                            }
                                            className="flex flex-row w-36 items-center text-sm text-gray-700 hover:bg-indigo-100 cursor-pointer px-1 py-0.5 rounded-md"
                                        >
                                            <IoIosAddCircle className="w-4 h-4 text-indigo-600" />
                                            <span className="pl-2">
                                                Add participant
                                            </span>
                                        </span>
                                    ) : (
                                        <span className="flex flex-row w-36 items-center text-sm text-gray-900 px-1 py-0.5 rounded-md">
                                            <IoIosCheckmarkCircle className="w-4 h-4 text-green-600" />
                                            <span className="pl-2">
                                                {sub_participant.full_name}
                                            </span>
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            <AddParticipant
                openAddParticipant={openAddParticipant}
                setOpenAddParticipant={setOpenAddParticipant}
            />
        </div>
    );
}

function BookingDataPage() {
    const { booking_id } = useParams();

    return (
        <div className="flex flex-row">
            <SideBar />
            <div className="w-full mx-10">
                <Header />
                <BookingDetails />
            </div>
        </div>
    );
}

export default BookingDataPage;
