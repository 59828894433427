/** @format */
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../../../components/sideBar";
import Header from "../../../../components/header";
import CustomerBookings from "./recentBookings";
import { useEffect, useState } from "react";
import {
    createCustomer,
    fetchCustomerData,
    updateCustomerData,
} from "../api/customersApi";
import Customer from "../../../../models/customers";
import LoadingWheel from "../../../../components/loadingWheel";
import { FaRegEdit } from "react-icons/fa";
import CustomerForm from "../customerForm";
import DeleteCustomerModal from "../deleteCustomerModal";
import { SuccessNotification } from "../../../../components/statusNotifications";

function InformationActions({
    customer,
    setOpenEditCustomer,
    setOpenDeleteModal,
}: {
    customer: Customer | null;
    setOpenEditCustomer: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    return (
        <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col">
                <span className="text-2xl font-bold">{customer?.email}</span>
                <span className="text-xl font-regular text-gray-600">
                    {customer?.customer_id}
                </span>
            </div>
            <div className="flex flex-row space-x-4">
                <button
                    onClick={() => setOpenEditCustomer(true)}
                    className="flex h-7 flex-row items-center text-xs px-2 border border-gray-400 shadow-sm rounded-md text-black"
                >
                    <FaRegEdit className="w-4 h-4" />{" "}
                    <span className="pl-1.5">Edit customer</span>
                </button>
                <button
                    onClick={() => setOpenDeleteModal(true)}
                    className="flex h-7 flex-row items-center text-xs px-2 bg-red-600 rounded-md text-white"
                >
                    <MdOutlineDelete className="w-4 h-4" />{" "}
                    <span className="pl-1.5">Delete customer</span>
                </button>
            </div>
        </div>
    );
}

interface AccountDetailsProps {
    customer: Customer | null;
}

function AccountDetails({ customer }: AccountDetailsProps) {
    return (
        <div className="pt-10">
            <span className="font-bold text-xl">Account Details</span>
            <dl className="mt-3 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Full name
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                            {customer?.first_name + " " + customer?.last_name}
                        </div>
                    </dd>
                </div>
                <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Email address
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">{customer?.email}</div>
                    </dd>
                </div>
                <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Phone Number
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                            {customer?.phone_number}
                        </div>
                    </dd>
                </div>
                <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Date of Birth{" "}
                        <span className="font-normal text-gray-500">
                            {"(yyyy-mm-yy)"}
                        </span>
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">{customer?.dob}</div>
                    </dd>
                </div>
            </dl>
        </div>
    );
}

function CustomerDataPage() {
    const { customer_id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [customer, setCustomer] = useState<Customer | null>(null);

    const [openEditCustomer, setOpenEditCustomer] = useState(false);
    const [updatedCustomer, setUpdatedCustomer] = useState(true);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deletedCustomer, setDeletedCustomer] = useState(false);
    const [unusedVar, setUnusedVar] = useState<Customer[]>([]);

    useEffect(() => {
        if (updatedCustomer) {
            fetchCustomerData(customer_id, setLoading, setCustomer);
        }
        setUpdatedCustomer(false);
    }, [updatedCustomer]);

    useEffect(() => {
        if (deletedCustomer) {
            navigate("/dashboard/customers");
        }
    }, [deletedCustomer]);

    return (
        <div className="flex flex-row">
            <SideBar />
            <div className="flex flex-col w-full mx-4 sm:mx-10">
                <Header />

                {!loading ? (
                    <div>
                        <InformationActions
                            customer={customer}
                            setOpenEditCustomer={setOpenEditCustomer}
                            setOpenDeleteModal={setOpenDeleteModal}
                        />

                        <AccountDetails customer={customer} />
                        <CustomerBookings />
                    </div>
                ) : (
                    <div className="flex flex-grow items-center h-screen justify-center pb-64">
                        <LoadingWheel />
                    </div>
                )}
            </div>
            <CustomerForm
                openCustomerForm={openEditCustomer}
                setOpenCustomerForm={setOpenEditCustomer}
                setCustomerActionSuccess={setUpdatedCustomer}
                initialValues={{
                    email: customer?.email || "", // If customer exists, use customer.email, otherwise empty string
                    firstName: customer?.first_name || "", // Use customer.firstName or an empty string
                    lastName: customer?.last_name || "", // Use customer.lastName or an empty string
                    phone: customer?.phone_number || "", // Use customer.phone or an empty string
                    dobDay: "1" || "", // Use customer.dobDay or an empty string
                    dobMonth: "January" || "", // Use customer.dobMonth or an empty string
                    dobYear: "2007" || "", // Use customer.dobYear or an empty string
                }}
                mode="edit"
                customer_id={customer_id}
            />

            <DeleteCustomerModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                selectedCustomers={[customer_id ?? ""]}
                customers={[]}
                setCustomers={setUnusedVar}
                setDeletedRows={setDeletedCustomer}
                mode="single"
                allCustomersSelected={false}
            />

            <SuccessNotification
                title="Successfully deleted the customer"
                subtitle=""
                showNotification={deletedCustomer}
                setShowNotification={setDeletedCustomer}
            />
        </div>
    );
}

export default CustomerDataPage;
