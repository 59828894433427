/** @format */
import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { createCustomer } from "./api/bookingApi";
import LoadingWheel from "../../../components/loadingWheel";
import { XCircleIcon } from "@heroicons/react/20/solid";

const days = Array.from({ length: 31 }, (_, i) => i + 1);

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const currentYear = new Date().getFullYear(); // Get the current year
const startYear = 1900; // Define the start year
const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => startYear + i
).reverse();

function ErrorTag({ errorMessage }: { errorMessage: string }) {
    return (
        <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-red-400"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                        {errorMessage}
                    </h3>
                </div>
            </div>
        </div>
    );
}

interface AddBookingProps {
    openAddBooking: boolean;
    setOpenAddBooking: React.Dispatch<React.SetStateAction<boolean>>;
    setCreatedBooking: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddBooking({
    openAddBooking,
    setOpenAddBooking,
    setCreatedBooking,
}: AddBookingProps) {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [dobDay, setDobDay] = useState("");
    const [dobMonth, setDobMonth] = useState("");
    const [dobYear, setDobYear] = useState("");

    const [missingField, setMissingField] = useState(false);

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const canSubmit = () => {
        if (
            email === "" ||
            firstName === "" ||
            lastName === "" ||
            phone === "" ||
            dobDay === "" ||
            dobMonth === "" ||
            dobYear === ""
        ) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (!openAddBooking) {
            const timer = setTimeout(() => {
                setEmail("");
                setFirstName("");
                setLastName("");
                setPhone("");
                setDobDay("");
                setDobMonth("");
                setDobYear("");
                setMissingField(false);
                setErrorMessage("");
            }, 300); // Adjust the delay time as needed

            return () => clearTimeout(timer);
        }
    }, [openAddBooking]);

    return (
        <Dialog
            open={openAddBooking}
            onClose={setOpenAddBooking}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={() => setOpenAddBooking(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>
                        <div className="absolute right-0 bottom-0 hidden pr-4 pb-4 sm:block">
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={
                                        canSubmit()
                                            ? () =>
                                                  createCustomer(
                                                      email,
                                                      firstName,
                                                      lastName,
                                                      phone,
                                                      dobDay,
                                                      dobMonth,
                                                      dobYear,
                                                      setCreatedBooking,
                                                      setLoading,
                                                      setOpenAddBooking,
                                                      setErrorMessage
                                                  )
                                            : () => setMissingField(true)
                                    }
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto items-center"
                                >
                                    {loading ? (
                                        <span className="pr-2 items-center flex flex-row justify-center">
                                            <LoadingWheel
                                                size="small"
                                                color="white"
                                            />
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    Add booking
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setOpenAddBooking(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="sm:flex sm:items-start w-full">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold leading-6 text-gray-900"
                                >
                                    Create booking
                                </DialogTitle>
                                <div className="mt-6 space-y-0.5 flex-col flex">
                                    {errorMessage !== "" ? (
                                        <ErrorTag errorMessage={errorMessage} />
                                    ) : (
                                        <></>
                                    )}
                                    {missingField ? (
                                        <span className="text-sm text-red-500">
                                            Please fill out all of the fields.
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <span className="font-medium text-sm">
                                        Email
                                    </span>
                                    <input
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        placeholder="you@example.com"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="mt-6 space-y-0.5">
                                    <span className="font-medium text-sm">
                                        First Name
                                    </span>
                                    <input
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="mt-6 space-y-0.5">
                                    <span className="font-medium text-sm">
                                        Last Name
                                    </span>
                                    <input
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="mt-6 space-y-0.5">
                                    <span className="font-medium text-sm">
                                        Phone Number
                                    </span>
                                    <input
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="mt-6 flex flex-row space-x-4">
                                    <div className="w-full">
                                        <span className="font-medium text-sm">
                                            DOB Day
                                        </span>
                                        <select
                                            value={dobDay}
                                            onChange={(e) =>
                                                setDobDay(e.target.value)
                                            }
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value="">
                                                --Please choose an option--
                                            </option>
                                            {days.map((day) => (
                                                <option key={day}>{day}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="w-full">
                                        <span className="font-medium text-sm">
                                            DOB Month
                                        </span>
                                        <select
                                            value={dobMonth}
                                            onChange={(e) =>
                                                setDobMonth(e.target.value)
                                            }
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value="">
                                                --Please choose an option--
                                            </option>
                                            {months.map((month) => (
                                                <option key={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="w-full">
                                        <span className="font-medium text-sm">
                                            DOB Year
                                        </span>
                                        <select
                                            value={dobYear}
                                            onChange={(e) =>
                                                setDobYear(e.target.value)
                                            }
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value="">
                                                --Please choose an option--
                                            </option>
                                            {years.map((year) => (
                                                <option key={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
