/** @format */
import { AiOutlineShop } from "react-icons/ai";

import {
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    CreditCardIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

const navigation = [
    { name: "Dashboard", href: "/dashboard", icon: HomeIcon },
    { name: "Bookings", href: "/dashboard/bookings", icon: UsersIcon },
    { name: "Customers", href: "/dashboard/customers", icon: FolderIcon },
    { name: "Reports", href: "/dashboard/reports", icon: ChartPieIcon },
    {
        name: "Memberships",
        href: "/dashboard/memberships",
        icon: CreditCardIcon,
    },
];

export default function SideBar() {
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        var newNormalizedPath =
            normalizedPath.split("/")[0] +
            "/" +
            normalizedPath.split("/")[1] +
            "/" +
            normalizedPath.split("/")[2];
        console.log(newNormalizedPath);
        setCurrentPath(newNormalizedPath);
    }, []);

    return (
        <div className="hidden lg:block">
            <div className="flex flex-row">
                {/* Apply fixed position */}
                <div className="fixed top-0 w-64 pl-4 pr-2 h-screen pt-6">
                    <div className="hover:bg-gray-100 text-xs font-bold flex flex-row items-center p-2 space-x-3 rounded-md">
                        <AiOutlineShop className="w-4 h-4" />
                        <span>Shock Grip</span>
                    </div>
                    <div className="pt-10"></div>
                    {navigation.map((item) => (
                        <div className="pt-1" key={item.name}>
                            <a
                                href={item.href}
                                className={`${
                                    currentPath === item.href
                                        ? "bg-gray-100 text-indigo-600"
                                        : "hover:bg-gray-100 hover:text-indigo-600 group"
                                } text-gray-700 flex flex-row items-center space-x-3 rounded-md p-2`}
                            >
                                <item.icon
                                    className={`w-4 h-4 text-gray-500 group-hover:text-indigo-600 ${
                                        currentPath === item.href
                                            ? "text-indigo-600"
                                            : ""
                                    }`}
                                />
                                <span className="text-xs font-medium">
                                    {item.name}
                                </span>
                            </a>
                        </div>
                    ))}
                </div>
                {/* Add margin to content to account for the fixed sidebar */}
                <div className="ml-64">
                    <div className="h-screen w-px bg-gray-300"></div>
                </div>
            </div>
        </div>
    );
}
