/** @format */

import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../components/loadingWheel";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { FaSearch } from "react-icons/fa";
import { fetchCustomers } from "../../customers/api/customersApi";
import Customer from "../../../../models/customers";
import Results from "../../../../models/results";
import { Filters } from "../../customers/customersTable";
import { searchCustomers } from "../../customers/api/customersApi";

const customers = [
    {
        full_name: "Andre Maytorena",
        email: "andremayto@gmail.com",
        customer_id: "cus_12412s4",
    },
    {
        full_name: "Andre Maytorena",
        email: "andremayto@gmail.com",
        customer_id: "cus_124s124",
    },
    {
        full_name: "Andre Maytorena",
        email: "andremayto@gmail.com",
        customer_id: "cus_12412f4",
    },
    {
        full_name: "Andre Maytorena",
        email: "andremayto@gmail.com",
        customer_id: "cus_1s24124",
    },
    {
        full_name: "Andre Maytorena",
        email: "andremayto@gmail.com",
        customer_id: "cus_124v124",
    },
    {
        full_name: "Andre Maytorena",
        email: "andremayto@gmail.com",
        customer_id: "cus_1241e24",
    },
];

export default function AddParticipant({
    openAddParticipant,
    setOpenAddParticipant,
}: {
    openAddParticipant: boolean;
    setOpenAddParticipant: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [selectedParticipant, setSelectedParticipant] = useState({
        customer_id: "",
    });

    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });
    const [filters, setFilters] = useState<Filters>({ email: "" });

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        if (openAddParticipant) {
            fetchCustomers(setLoading, setCustomers, setResults, null, filters);
        }
    }, [openAddParticipant]);

    useEffect(() => {
        searchCustomers(searchText, setCustomers, setLoading);

        return () => searchCustomers.cancel();
    }, [searchText]);

    return (
        <Dialog
            open={openAddParticipant}
            onClose={() => setOpenAddParticipant(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenAddParticipant(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Add participant
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4">
                            <div className="group flex flex-row items-center space-x-3 px-2 h-8 rounded-md bg-gray-100 hover:bg-gray-100 focus-within:bg-gray-100 w-full">
                                <FaSearch className="text-gray-400 w-2.5 h-2.5 ml-1" />
                                <input
                                    value={searchText}
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                    placeholder="Search"
                                    className="outline-none px-0 py-0 text-xs bg-transparent h-8 w-full border-transparent focus:border-transparent focus:ring-0"
                                />
                            </div>
                        </div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4">
                            {loading ? (
                                <div className="flex justify-center pt-20">
                                    <LoadingWheel />
                                </div>
                            ) : (
                                <div className="text-center sm:text-left w-full">
                                    {customers.map((customer: Customer) => (
                                        <div
                                            key={customer.first_name}
                                            // className="py-1.5 hover:bg-indigo-200 text-gray-800 cursor-pointer rounded-md"
                                            className={`${
                                                selectedParticipant.customer_id ===
                                                customer.customer_id
                                                    ? "bg-indigo-600 text-white"
                                                    : "hover:bg-indigo-200 text-gray-800"
                                            } py-1.5  cursor-pointer rounded-md`}
                                            onClick={() =>
                                                setSelectedParticipant(customer)
                                            }
                                        >
                                            <span className="text-sm pl-2">
                                                {customer.first_name +
                                                    " " +
                                                    customer.last_name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto items-center"
                            >
                                Add participant
                            </button>
                            <button
                                type="button"
                                onClick={() => setOpenAddParticipant(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
