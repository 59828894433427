import React, { useState, useEffect, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { LuChevronsUpDown } from "react-icons/lu";

// Adjusted to start week on Monday
const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

const MultiCalendar: React.FC = () => {
    const today = dayjs();
    const yesterday = today.subtract(1, "day");

    const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
    const [selectedDates, setSelectedDates] = useState<{
        start: Dayjs | null;
        end: Dayjs | null;
    }>({ start: yesterday, end: today });
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);

    const calendarRef = useRef<HTMLDivElement>(null);
    const toggleButtonRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                calendarRef.current &&
                toggleButtonRef.current &&
                !calendarRef.current.contains(event.target as Node) &&
                !toggleButtonRef.current.contains(event.target as Node)
            ) {
                setIsCalendarVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const startOfMonth = currentDate.startOf("month");
    const endOfMonth = currentDate.endOf("month");

    // Helper function to get start of week on Monday
    const getStartOfWeekMonday = (date: Dayjs) => {
        const weekday = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const diff = (weekday + 6) % 7; // Calculate difference to Monday
        return date.subtract(diff, "day").startOf("day");
    };

    const startDate = getStartOfWeekMonday(startOfMonth);
    const endDate = getStartOfWeekMonday(endOfMonth).add(6, "days");

    const handleDateClick = (date: Dayjs) => {
        if (date.isAfter(today, "day")) return;

        if (selectedDates.start && !selectedDates.end) {
            if (date.isBefore(selectedDates.start)) {
                setSelectedDates({ start: date, end: selectedDates.start });
            } else {
                setSelectedDates({ ...selectedDates, end: date });
            }
        } else {
            setSelectedDates({ start: date, end: null });
        }
    };

    const generateCalendarDays = () => {
        const days: Dayjs[] = [];
        let day = startDate;

        while (day.isBefore(endDate, "day") || day.isSame(endDate, "day")) {
            days.push(day);
            day = day.add(1, "day");
        }

        return days;
    };

    const handlePrevMonth = () => {
        setCurrentDate(currentDate.subtract(1, "month"));
    };

    const handleNextMonth = () => {
        const nextMonth = currentDate.add(1, "month");
        if (!nextMonth.isAfter(today, "month")) {
            setCurrentDate(nextMonth);
        }
    };

    const handleToggleCalendar = () => {
        if (!isCalendarVisible && selectedDates.start) {
            setCurrentDate(selectedDates.start);
        }
        setIsCalendarVisible(!isCalendarVisible);
    };

    const days = generateCalendarDays();

    const isNextMonthDisabled = currentDate
        .add(1, "month")
        .isAfter(today, "month");

    const isDayBetween = (day: Dayjs) => {
        if (!selectedDates.start || !selectedDates.end) return false;
        return (
            day.isAfter(selectedDates.start, "day") &&
            day.isBefore(selectedDates.end, "day")
        );
    };

    const getDisplayText = () => {
        if (
            selectedDates.start?.isSame(today, "day") &&
            selectedDates.end?.isSame(today, "day")
        ) {
            return "Today - Today";
        } else if (
            selectedDates.start?.isSame(yesterday, "day") &&
            selectedDates.end?.isSame(yesterday, "day")
        ) {
            return "Yesterday - Yesterday";
        } else if (
            selectedDates.start?.isSame(yesterday, "day") &&
            selectedDates.end?.isSame(today, "day")
        ) {
            return "Yesterday and Today";
        } else if (
            selectedDates.start &&
            selectedDates.end?.isSame(today, "day")
        ) {
            return `${selectedDates.start.format("MMMM D, YYYY")} - Today`;
        } else if (
            selectedDates.start &&
            selectedDates.end?.isSame(yesterday, "day")
        ) {
            return `${selectedDates.start.format("MMMM D, YYYY")} - Yesterday`;
        } else if (selectedDates.start && selectedDates.end) {
            return `${selectedDates.start.format(
                "MMMM D, YYYY"
            )} - ${selectedDates.end.format("MMMM D, YYYY")}`;
        } else if (selectedDates.start) {
            if (selectedDates.start.isSame(today, "day")) {
                return "Today";
            } else if (selectedDates.start.isSame(yesterday, "day")) {
                return "Yesterday";
            } else {
                return selectedDates.start.format("MMMM D, YYYY");
            }
        }

        return "Select a date";
    };

    return (
        <div className="relative">
            <div className="relative inline-block pt-8 z-0">
                <span
                    ref={toggleButtonRef}
                    className="font-medium text-sm items-center flex flex-row cursor-pointer hover:text-indigo-600"
                    onClick={handleToggleCalendar}
                >
                    {getDisplayText()}
                    <LuChevronsUpDown className="pl-2 h-6 w-6" />
                </span>
            </div>
            <div
                ref={calendarRef}
                className={`absolute left-0 top-full mt-2 max-w-sm mx-auto bg-white rounded-xl shadow-lg transition-all duration-100 ease-in-out w-full ${
                    isCalendarVisible
                        ? "scale-100 opacity-100"
                        : "scale-95 opacity-0 pointer-events-none"
                }`}
                style={{ transformOrigin: "top left" }}
            >
                <div className="p-4">
                    <div className="flex items-center justify-between mb-8">
                        <button
                            onClick={handlePrevMonth}
                            className="text-gray-600 hover:text-gray-800"
                        >
                            <GoChevronLeft className="h-5 w-5" />
                        </button>
                        <span className="text-sm font-semibold">
                            {currentDate.format("MMMM YYYY")}
                        </span>
                        <button
                            onClick={handleNextMonth}
                            className={`text-gray-600 hover:text-gray-800 ${
                                isNextMonthDisabled
                                    ? "cursor-not-allowed opacity-50"
                                    : ""
                            }`}
                            disabled={isNextMonthDisabled}
                        >
                            <GoChevronRight className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="grid grid-cols-7 text-center mb-3">
                        {daysOfWeek.map((day) => (
                            <div key={day} className="text-xs text-gray-500">
                                {day}
                            </div>
                        ))}
                    </div>
                    <div className="border border-gray-200 rounded-xl overflow-hidden">
                        <div className="grid grid-cols-7 gap-px bg-gray-200 cursor-pointer">
                            {days.map((day) => {
                                const isToday = day.isSame(today, "day");
                                const isSelected =
                                    (selectedDates.start &&
                                        day.isSame(
                                            selectedDates.start,
                                            "day"
                                        )) ||
                                    (selectedDates.end &&
                                        day.isSame(selectedDates.end, "day"));
                                const isCurrentMonth =
                                    day.month() === currentDate.month();
                                const isFuture = day.isAfter(today, "day");
                                const isBetween = isDayBetween(day);

                                let dayBackgroundClass =
                                    "bg-white hover:bg-gray-100 hover:cursor-pointer";
                                let dayTextClass = "text-gray-800";

                                if (!isCurrentMonth) {
                                    dayBackgroundClass =
                                        "bg-gray-50 hover:bg-gray-100 hover:cursor-pointer";
                                    dayTextClass = "text-gray-400";
                                }
                                if (isFuture) {
                                    dayBackgroundClass =
                                        "bg-gray-50 cursor-not-allowed";
                                    dayTextClass = "text-gray-400";
                                }

                                if (isBetween) {
                                    dayBackgroundClass = "bg-indigo-200";
                                }

                                if (isSelected) {
                                    dayBackgroundClass =
                                        "bg-indigo-600 cursor-pointer";
                                    dayTextClass = "text-white";
                                } else if (isToday) {
                                    dayTextClass = "text-indigo-600";
                                }

                                return (
                                    <div
                                        key={day.format("YYYY-MM-DD")}
                                        onClick={() =>
                                            !isFuture && handleDateClick(day)
                                        }
                                        className={`relative text-sm flex items-center justify-center h-11 ${dayBackgroundClass}`}
                                    >
                                        <span
                                            className={`relative ${dayTextClass}`}
                                        >
                                            {day.date()}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiCalendar;
