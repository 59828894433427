import KPI from "../../../models/kpi";

const retrieveKPI = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setKPIData: React.Dispatch<React.SetStateAction<KPI[]>> // Adjust type for dictionary
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/metrics/kpi`,
            {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setKPIData(data.data.kpis); // Assuming the entire data object is the dictionary
            setLoading(false);
        } else {
            setLoading(false);
            console.error("Failed to retrieve account details", data);
        }
    } catch (error) {
        setLoading(false);
        console.error("Error fetching account details", error);
    }
};

export default retrieveKPI;
