/** @format */

import React from "react";
import logo from "./logo.svg";
import CustomersPage from "./pages/dashboard/customers/page";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/dashboard/home/page";
import BookingsPage from "./pages/dashboard/bookings/page";
import PageNotFound from "./pages/not-found/page";
import LoginPage from "./pages/authentication/login/page";
import LandingPage from "./pages/landing/page";
import CustomerDataPage from "./pages/dashboard/customers/customer-data/page";
import SettingsPage from "./pages/dashboard/settings/page";
import ContactPage from "./pages/contact/page";
import BookingDataPage from "./pages/dashboard/bookings/booking-data/page";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                {/* Landing Page Routes */}
                <Route path="/" element={<LandingPage />} />

                {/* Contact Page Routes */}
                <Route path="/contact" element={<ContactPage />} />

                {/* Dashboard Routes */}
                <Route path="/dashboard" element={<HomePage />} />

                {/* Dashboard Customer Routes */}
                <Route
                    path="/dashboard/customers"
                    element={<CustomersPage />}
                />
                <Route
                    path="/dashboard/customers/:customer_id"
                    element={<CustomerDataPage />}
                />

                <Route path="/dashboard/settings" element={<SettingsPage />} />

                {/* Dashboard Booking Routes */}
                <Route path="/dashboard/bookings" element={<BookingsPage />} />
                <Route
                    path="/dashboard/bookings/:booking_id"
                    element={<BookingDataPage />}
                />

                {/* Authentication Routes */}
                <Route path="login" element={<LoginPage />} />

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
