/** @format */

import Header from "../../../components/header";
import SideBar from "../../../components/sideBar";
import KPI from "../../../models/kpi";
import Charts from "./charts";
import retrieveKPI from "./fetchData";
import KPICards from "./kpiCards";
import LineChart from "./lineChart";
import { useEffect, useState } from "react";

function HomePage() {
    return (
        <div className="flex flex-row">
            <SideBar />
            <div className="sm:px-10 px-4 flex-grow flex flex-col">
                <Header />
                <div>
                    <KPICards />
                </div>
                <div className="h-[1px] w-full bg-gray-200 mt-10"></div>
                <div>
                    <Charts />
                </div>
            </div>
        </div>
    );
}

export default HomePage;
