import SideBar from "../../../components/sideBar";
import SettingsGeneral from "./settingsGeneral";

export default function SettingsPage() {
    return (
        <div className="flex flex-row">
            <SideBar />
            <SettingsGeneral />
        </div>
    );
}
